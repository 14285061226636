.primary-section {
  .main-content {
    h1 {
      color: var(--primary-color);
      font-size: var(--large-title-size);
      font-weight: var(--bold-font);
      line-height: 1.2;
      margin-bottom: 0 !important;
      span {
        font-family: var(--text-font);
      }
    }
    p {
      font-size: var(--paragraph-size);
      color: var(--fourth-color);
    }

    button {
      font-size: 15px !important;
    }

    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 15px;
      }
    }
  }

  .image-content {
    .image-container {
      height: 280px;
      overflow: hidden;
      border-radius: var(--border-radius);
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        // Center
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .review-content {
      margin-top: 20px;
      background-color: var(--secondary-color);
      border-radius: var(--border-radius);
      box-shadow: var(--shadow);
      padding: 30px 15px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h4 {
          font-size: var(--title-size);
          font-family: var(--text-font);
          color: var(--fourth-color);
        }
        p {
          color: var(--primary-color);
          font-size: 16px;
        }
      }
    }
  }
}

.item-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
  background-color: var(--secondary-color);
  border-radius: 15px;

  .icon-holder {
    font-size: 30px;
    color: #79393d;
  }

  h5 {
    font-size: 18px;
    font-family: var(--text-font);
    color: var(--fourth-color);
    font-weight: var(--bold-font);
  }
  p {
    color: var(--primary-color);
    font-size: 16px;
  }
}

.section {
  h2 {
    font-size: var(--title-size);
    color: var(--primary-color);
    font-weight: var(--bold-font);
  }
}

.curtains-section-wrapper {
  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.curtains-card {
  position: relative;
  padding: 10px;
  background-color: var(--secondary-color);
  border-radius: 15px;
  height: 460px;
  box-shadow: var(--shadow);
  h3 {
    margin-top: 15px;
    font-size: 20px;
    color: var(--fourth-color);
    font-family: var(--text-font);
  }
  p {
    color: var(--primary-color);
    font-size: 16px;
  }

  .image-holder {
    height: 300px;
    overflow: hidden;
    background-color: white;
    border-radius: 15px;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
  }
}

.faq-section {
  .ant-collapse-header {
    font-size: 20px;
    background-color: var(--secondary-color);
    //.ant-collapse-header-text {
    //  color: var(--fourth-color);
    //}
  }
  p {
    font-size: var(--paragraph-size);
  }
}

.tabs-section {
  .ant-tabs-tab-btn {
    font-weight: var(--bold-font);
    font-size: 20px;
    color: var(--fourth-color);
  }
}
