.navbar-illusion {
  height: 100px;
  width: 100%;
}

.navbar-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  .navbar-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    margin: 15px 25px;
    height: 60px;
    padding: 15px 15px;
    box-shadow: rgba(17, 17, 26, 0.05) 0 1px 0, rgba(17, 17, 26, 0.1) 0 0 8px;
    @media screen and (max-width: 800px) {
      padding: 15px 30px;
      height: 50px !important;
      margin: 15px 15px !important;
    }
  }

  .nav-items {
    ul {
        display: flex;
        gap: 1.5rem;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            a {
              color: var(--primary-color);
              font-size: var(--paragraph-size);
              font-weight: var(--bold-font);
              text-decoration: none;
              &:hover {
                  color: var(--fourth-color);
              }
            }
        }
    }
  }
};

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 110vh;
  padding: 20px;
  z-index: 9999;
  background-color: var(--secondary-color);
  ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        color: var(--primary-color);
        font-size: var(--paragraph-size);
        font-weight: var(--bold-font);
        text-decoration: none;
        &:hover {
          color: var(--fourth-color);
        }
      }
    }
  }
  animation: slide-in 0.5s forwards;
}

.slide-in {
  animation: slide-in 0.5s forwards;
}

// Slide From Left Animation
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
