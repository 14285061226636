.estimate-section-wrapper {
  h3 , h4 {
    font-family: var(--text-font) !important;
  }

  h2 {
    font-size: var(--title-size);
    color: var(--primary-color);
    font-weight: var(--bold-font);
  }

  h3 {
    font-size: var(--title-size);
    color: var(--primary-color);
    span {
      font-size: var(--paragraph-size);
      color: var(--fourth-color);
    }
  }

  @media screen and (max-width: 800px) {
    h3 {
      font-size: 30px !important;
      span {
        font-size: 12px !important;
      }
    }
  }

  .faq-section {
    h3 {
      font-size: var(--title-size);
      color: var(--primary-color);
      font-family: var(--title-font);
    }
  }

  .input-element {
    display: flex;
    flex-direction: column;
  }

  .style-card {
    position: relative;
    padding: 10px;
    min-width: 100px;
    border: 1px solid var(--primary-color);
    border-radius: 15px;
    box-shadow: var(--shadow);
    h3 {
      font-size: var(--title-size);
      color: var(--fourth-color);
      font-family: var(--title-font);
    }
    p {
      color: var(--primary-color);
      font-size: 16px;
      text-align: center;
    }

    .image-holder {
      height: 80px;
      overflow: hidden;
      background-color: var(--secondary-color);
      border-radius: 15px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      background-color: var(--secondary-color);
      cursor: pointer;
    }

    &.selected {
      background-color: var(--primary-color);
      p {
        color: var(--secondary-color) !important;
      }

      // Add An Arrow Down At The Bottom
      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--primary-color);
      }
    }

    @media screen and (max-width: 800px) {
      padding: 5px !important;
      min-width: 50px !important;
      p {
        font-size: 12px;
      }
      .image-holder {
        height: 60px !important;
      }
    }
  }

  label {
    font-size: 16px;
    color: var(--fourth-color);
  }

  .presets-section {
    button {
      font-family: var(--text-font);
      padding: 2px 5px;
      border-radius: 5px;
      border: 1px solid var(--primary-color);

      &:hover {
        background-color: var(--primary-color);
        color: var(--secondary-color);
      }
    }
  }
}