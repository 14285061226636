.book-root {
  label {
    font-size: 15px !important;
    color: var(--fourth-color) !important;
  }

  h1 {
    font-family: var(--text-font) !important;
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
    font-weight: var(--bold-font);
  }

  .tag-element {
    padding: 5px 10px;
    font-size: 14px !important;
    border-radius: 5px;
    background-color: var(--secondary-color);
    margin: 5px;
    float: left;
    cursor: pointer;
    min-width: 100px;

    &.selected {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }
  }
}

.confirmation-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  text-align: center;

  .icon {
    font-size: 50px;
    color: var(--fourth-color)
  }

  h3 {
    font-size: 1.5rem;
    color: var(--fourth-color);
  }

  p {
    font-size: var(--paragraph-size);
    b {
        color: var(--primary-color);
    }
  }
}
