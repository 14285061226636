@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #78383C;
  --secondary-color: #E2DED3;
  --third-color: #E1C2AA;
  --fourth-color: #CC8950;

  --large-title-size: 50px;
  --title-size: 30px;
  --paragraph-size: 18px;

  --border-radius: 35px;
  --bold-font: 900;
  --shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --title-font: 'Mulish', 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  --text-font: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

html, body {
  position: relative;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

h1 {
  font-size: var(--large-title-size);
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 35px !important;
  }
}

h2 {
  font-size: var(--title-size);
}

h1 {
  font-family: var(--title-font);
}

h2, h3, h4, h5, h6, p, span, ul, li, input, label, a, button {
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button, a {
  transition: all 0.3s;

  img {
    :hover {
      filter: brightness(0.8);
    }
  }

  &.large {;
    font-weight: var(--bold-font);
    padding: 10px 15px !important;
    font-size: var(--paragraph-size);

    &:hover {
      background-color: var(--fourth-color);
    }
  }

  &.primary-button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: var(--border-radius);
    font-weight: var(--bold-font);
    padding: 5px 15px;

    &:hover {
      background-color: var(--fourth-color);
    }
  }

  &.outlined-button {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    font-weight: var(--bold-font);
    padding: 5px 15px;

    &:hover {
      background-color: var(--fourth-color);
      color: var(--secondary-color);
    }
  }
}

$breakpoints: (
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xl': 1200px,
        'xxl': 1400px
);

$max-container-width: (
        'sm': 350px,
        'md': 720px,
        'lg': 960px,
        'xl': 1140px,
        'xxl': 1320px
);

.custom-container {
  position: relative;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $min-width in $breakpoints {
    @media (min-width: $min-width) {
      max-width: map-get($max-container-width, $breakpoint);
    }
  }
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}

.swiper-wrapper {
 padding-bottom: 40px;
}

.swiper-pagination {
  bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

.swiper-pagination-bullet {
  border-radius: 5px !important;
  height: 15px !important;
  width: 15px !important;
}

.whats-app-button-wrapper{
  position: fixed;
  bottom: 20px;
  right: 10px;
  img {
    width: 60px;
    height: 60px;
  }
  z-index: 999999;
}
