.root-privacy {
  h1 {
    font-size: 35px;
    color: var(--fourth-color);
    text-align: center;
  }

  h2 {
    color: var(--primary-color);
  }

  p {
    margin-bottom: 20px;
  }
}