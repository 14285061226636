.footer-root {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--secondary-color);

  .logo-holder {
    width: 200px;
    img {
      width: 100%;
    }
  }

  .address-section {
    border-left: 2px solid var(--primary-color);
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon {
      color: var(--fourth-color);
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  h4 {
    margin-top: 30px;
    font-size: var(--text-font);
    font-weight: var(--bold-font);
    color: var(--fourth-color);
  }

  .follow-us-section {
    margin-top: 10px;
    font-size: 25px;
    color: var(--primary-color);

    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
  }

  .links-content {
    @media screen and (max-width: 800px) {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}