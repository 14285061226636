.about-wrapper {
  .top-section {
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .title-section {
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-content {
      h1 {
        font-family: var(--text-font) !important;
        font-size: 2rem !important;
        color: var(--primary-color);
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
          margin-bottom: 5px !important;
        }
      }
      p {
        font-size: 1.2rem;
        color: var(--fourth-color);
        text-align: center;
      }

      text-align: center;
      background-color: var(--secondary-color);
      border-radius: 15px;
      padding: 30px 10px;

      @media screen and (max-width: 768px) {
        margin-left: 20px !important;
        margin-right: 20px !important;

        p {
            font-size: 1rem;
        }
      }
    }
  }

  .story-section {
    text-align: center;
    h2 {
        font-size: 2rem;
        color: var(--primary-color);
        margin-bottom: 20px;
    }
    p {
        font-size: var(--paragraph-size);
        color: var(--fourth-color);
      .special {
        color: var(--primary-color);
      }
    }
  }
}

.team-section {
  h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
  }

  .team-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .team-card {
      position: relative;
      width: 350px;
      height: 400px;
      background-color: var(--fourth-color);
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 15px;
      }
      .team-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(226, 222, 211, 0.85);
        padding: 20px 10px;
        text-align: center;
        h3 {
          font-size: 1.5rem;
          color: var(--primary-color);
          margin-bottom: 0;
          line-height: 1.2;
        }
        p {
            font-size: var(--paragraph-size);
            color: var(--fourth-color);
        }
      }

      @media screen and (max-width: 768px) {
        width: 200px;
        height: 300px;
      }
    }
  }
}

.reviews-section {
  text-align: center;
  h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
  }
}